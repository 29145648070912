import React, { useState } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ApiServices from "../../http/api_services";
import { Spinner } from "react-bootstrap";
import EmailSent from "../../components/EmailSent";

function Contact() {
  const [value, setValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleFormChange = (e) => {
    setValue((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const { name, email, message } = value;
    const requestBody = {
      name,
      email,
      message,
    };
    ApiServices.sendMessage(requestBody, (error, response) => {
      if (error) { 
        setLoading(false);
        return;
      }
      setLoading(false);
      // setShowModal(true);
    });
  };

  const handleClose = () => setShowModal(false);

  return (
    <div>
      {showModal && <EmailSent show={showModal} handleClose={handleClose} />}
      <Header
        title={"Contact Me"}
        subTitle={"Have questions? I have answers"}
        headerImage={"/assets/contact.jpeg"}
      />
      <Container className="mx-auto">
        <Row className="justify-content-center">
          <Col lg={8} md={10} xl={7}>
            <p className="text-center fs-5" style={{ margin: "2rem" }}>
              Want to get in touch? Fill out the form below to send me a message
              and I will get back to you as soon as possible!
            </p>
            <Form className="mx-5 mt-5 mb-5">
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="name"
                  placeholder="Enter name"
                  name="name"
                  onChange={handleFormChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  onChange={handleFormChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Enter message here..."
                  name="message"
                  onChange={handleFormChange}
                />
              </Form.Group>
              <Button
                onClick={handleSubmit}
                className="px-4 py-3 text-uppercase btn btn-primary font-weight-bold"
                style={{ letterSpacing: "1.5px" }}
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Send"
                )}
              </Button>
            </Form>
          </Col>
          <Footer />
        </Row>
      </Container>
    </div>
  );
}

export default Contact;
